import React from 'react';
import './App.css';

interface AppDef {
  name: string;
  href: string;
  desc: string;
  lang: 'ko' | 'en'
}

function App() {
  const apps: Array<AppDef> = [
    {
      name: 'Lucky Finger',
      href: './luckyfinger/',
      desc: 'Random pick!',
      lang: 'en'
    },
    {
      name: '럭키 핑거',
      href: './luckyfinger/',
      desc: '3초 복불복!!',
      lang: 'ko'
    },
  ]
  return (
    <div className="App">
      <header className="App-header">

        {apps
          .filter(app => navigator.language.indexOf('ko') === 0 ? (app.lang === 'ko') : (app.lang === 'en'))
          .map((app, idx) => {
            return <div key={idx}>
              <a
                className="App-link"
                href={app.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {app.name}
              </a>
              <p className="description">{app.desc}</p>
              <br />
              <br />
              <a style={{ color: 'gray', textDecoration: 'none', display: 'flex', alignItems: 'center' }} href='mailto:speedygame.zone@gmail.com'> &#128231;<span style={{ fontSize: '28px' }}>&nbsp;Feedback</span> </a>
            </div>
          })}

      </header>
    </div >
  );
}

export default App;
